import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import asyncComponent from 'util/asyncComponent';


export default function PrivateRoute(props) {

  const privateFor = props.privateFor || [];

  const isSuperuser = useSelector(state => state.auth.isSuperuser);
  const role = useSelector(state => state.company.currentCompany.role);

  const isPrivateForThisRole = privateFor.find(restrictedRole => restrictedRole === role);

  if (isPrivateForThisRole && !isSuperuser) {
    return (
      <Route component={asyncComponent(() => import('components/Error404'))} />
    );
  }

  return <Route {...props} />;

}
