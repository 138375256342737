import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import MenuItem from '@material-ui/core/MenuItem';
import { styled, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { switchCompany, fetchAllCompanies, resetIntegrationsData } from 'store/actions';
import {
  hasEditCompanyPermission, hasCompanyAdminsListPermission, hasNewUpdatesBadge, hasScheduleListPermission
} from 'util/adminRoles';

import CollapseBox from './CollapseBox';
import IntegrationsMenu from './IntegrationsMenu/IntegrationsMenu';
import generateCompanyObject from '../../util/switchCompanyObject';
import ListItemMenu from './ListItemMenu/index';

const SidebarDivider = styled(Divider)({
  backgroundColor: 'rgba(255, 255, 255, 0.6)'
});

const styles = {
  companiesListStyles: {
    height: '350px',
  },
  companiesRootStyles: {
    top: '130px !important',
  }
};

function SidenavContent({ classes, location }) {
  const dispatch = useDispatch();

  const allCompanies = useSelector(state => state.company.allCompanies);
  const currentCompany = useSelector(state => state.company.currentCompany);
  const isSuperuser = useSelector(state => state.auth.isSuperuser);
  const isCompanyLoading = useSelector(state => state.company.loader);

  const isActiveCompanyMenu = () => {
    const path = location.pathname.slice(1);
    const subPath = path.split('/');
    return subPath.length === 3;
  };

  const {
    role, notifications
  } = currentCompany;

  const handleChange = () => (event) => {
    event.preventDefault();

    const companyId = event.target.value;

    dispatch(resetIntegrationsData());

    const selectedCompany = allCompanies.find(company => company.id === companyId);
    const preparedSelectedCompany = generateCompanyObject(selectedCompany || currentCompany);
    dispatch(switchCompany(preparedSelectedCompany));

  };

  const handleOpenSelectCompany = () => {
    dispatch(fetchAllCompanies({ currentCompanyID: currentCompany.id }));
  };

  const renderCompanies = (allCompanies) => {
    if (allCompanies) {
      return allCompanies.map(company => (
        <MenuItem
          disabled={isCompanyLoading}
          key={company.id}
          value={company.id}
        >
          {company.name}
        </MenuItem>
      ));
    }
    return null;
  };

  const getUrlString = currentCompany => `/app/companies/${currentCompany.id}`;

  const getNavLink = (companyId, item) => `/app/companies/${companyId}/${item}`;

  const ListItemHeaderGroupMenu = ({ titleTextId }) => (
    <li className="nav-header">
      <span><IntlMessages id={titleTextId} /></span>
    </li>
  );


  if (currentCompany.id) {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <ListItemMenu
            iconClass="zmdi-home zmdi-hc-fw"
            textId="sidebar.home"
            pathname="/app"
            useActiveClassName={false}
          />
          <li>
            <Select
              id="company"
              label="Companies"
              value={currentCompany.id}
              onChange={handleChange()}
              onOpen={handleOpenSelectCompany}
              fullWidth
              MenuProps={{
                classes: {
                  paper: classes.companiesRootStyles,
                  list: classes.companiesListStyles,
                }
              }}
              className="select-company"
              classes={{
                root: 'bg-light',
              }}
            >
              {renderCompanies(allCompanies)}
            </Select>
          </li>
          <ListItemMenu
            isVisible={isSuperuser || hasEditCompanyPermission(role)}
            iconClass="zmdi-edit zmdi-hc-ul"
            textId="sidebar.edit"
            pathname={getUrlString(currentCompany)}
            liStyles="bg-light change-company"
            useActiveClassName={false}
          />
          <ListItemHeaderGroupMenu titleTextId="sidebar.admin" />
          <ListItemMenu
            isVisible={isSuperuser || hasCompanyAdminsListPermission(role)}
            iconClass="zmdi-account zmdi-hc-fw"
            textId="sidebar.companyAdmins"
            pathname={getNavLink(currentCompany.id, 'admins')} />
          <ListItemMenu
            iconClass="zmdi-store zmdi-hc-fw"
            textId="sidebar.buildings"
            pathname={getNavLink(currentCompany.id, 'buildings')} />
          <ListItemMenu
            iconClass="zmdi-hq zmdi-hc-fw"
            textId="sidebar.companies"
            pathname="/app/companies"
            isActive={isActiveCompanyMenu()}
            isExact />
          <ListItemMenu
            iconClass="zmdi-camera-front zmdi-hc-fw"
            textId="sidebar.visitorCodes"
            pathname={getNavLink(currentCompany.id, 'vcodes')} />
        </ul>
        <SidebarDivider />
        <ul className="nav-menu">
          <ListItemHeaderGroupMenu titleTextId="sidebar.hardware" />
          <ListItemMenu
            iconClass="zmdi-tablet-android zmdi-hc-fw"
            textId="sidebar.visitorUnits"
            pathname={getNavLink(currentCompany.id, 'vunits')}
            badge={{ isVisible: isSuperuser || hasNewUpdatesBadge(role), count: notifications && notifications.visitor_units }} />
          <ListItemMenu
            iconClass="zmdi-balance zmdi-hc-fw"
            textId="sidebar.doorControllers"
            pathname={getNavLink(currentCompany.id, 'doorControllers')}
            badge={{ isVisible: isSuperuser || hasNewUpdatesBadge(role), count: notifications && notifications.access_control_boards }} />
        </ul>
        <SidebarDivider />
        <ul className="nav-menu">
          <ListItemHeaderGroupMenu titleTextId="sidebar.members" />
          <ListItemMenu
            isVisible={isSuperuser || hasScheduleListPermission(role)}
            iconClass="zmdi-time zmdi-hc-fw"
            textId="sidebar.schedules"
            pathname={getNavLink(currentCompany.id, 'schedules')} />
          <ListItemMenu
            iconClass="zmdi-nfc zmdi-hc-fw"
            textId="sidebar.accessLevels"
            pathname={getNavLink(currentCompany.id, 'accessLevels')} />
          <ListItemMenu
            iconClass="zmdi-accounts zmdi-hc-fw"
            textId="sidebar.members"
            pathname={getNavLink(currentCompany.id, 'members')} />
        </ul>
        <SidebarDivider />

        <IntegrationsMenu />

        <SidebarDivider />
        <ul className="nav-menu">
          <ListItemHeaderGroupMenu titleTextId="sidebar.loggingHistory" />
          <ListItemMenu
            iconClass="zmdi-collection-image zmdi-hc-fw"
            textId="sidebar.visitorImages"
            pathname={getNavLink(currentCompany.id, 'visitorImages')}
          />
          <ListItemMenu
            iconClass="zmdi-receipt zmdi-hc-fw"
            textId="sidebar.eventsTransactions"
            pathname={getNavLink(currentCompany.id, 'events')}
          />
          <CollapseBox
            items={[
              { name: <IntlMessages id="sidebar.doors" />, pathname: 'doorsReport' },
              { name: <IntlMessages id="sidebar.events" />, pathname: 'eventsReport' },
              { name: <IntlMessages id="sidebar.members" />, pathname: 'membersReport' },
              { name: <IntlMessages id="sidebar.visitorCodes" />, pathname: 'visitorCodesReport' },
              { name: <IntlMessages id="sidebar.errors" />, pathname: 'errorsReport' }
            ]}
            collapseLabel={<IntlMessages id="sidebar.reports" />}
            iconStyle="zmdi-view-web"
          />
        </ul>
      </CustomScrollbars>
    );
  }
  return null;
}

export default withRouter(withStyles(styles)(SidenavContent));
