const generateCompanyObject = company => ({
  id: company.id,
  name: company.name,
  notifications: company.notifications,
  subscriptionType: company.subscription_type || {},
  functionalityPackages: company.functionality_packages || {},
  turnOnDirectoryCode: company.turn_on_directory_code,
  pstnRollOverAfterMobileApp: company.pstn_roll_over_after_mobile_application,
  directoryCodeLength: company.directory_code_length,
  role: company.role,
});

export default generateCompanyObject;
